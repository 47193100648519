import React, { useState } from "react";

const Topbar = () => {
  return (
    <>
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex flex-wrap flex-sm-column align-items-center">
            <div className="item">
              <i className="bi bi-envelope d-flex align-items-center">
                <a href="mailto:melbournemalayaliassociation@gmail.com">
                  melbournemalayaliassociation@gmail.com
                </a>
              </i>
            </div>
            <div className="item">
              <i className="bi bi-phone d-flex align-items-center ms-4">
                <span>+61 412 429 469</span>
              </i>
            </div>
          </div>

          <div className="cta d-none d-md-flex align-items-center">
            <a href="#about" className="scrollto">
              Join Us
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Topbar;
