import React, { useState } from "react";
import SwiperEventModule from "../../Common/SwiperEventModule";

const Onam = () => {
  return (
    <>
      <section id="onam-2024" className="portfolio-details section-bg">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 justify-content-center">
              <div className="container" data-aos="fade-up">
                <SwiperEventModule></SwiperEventModule>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="portfolio-info">
                <h3>Onappulari 2024</h3>
                <ul>
                  <li>
                    <strong>Venue</strong>: Serbian Church Hall, 212 Diamond Creek Road, Greensborough
                  </li>
                  <li>
                    <strong>Date</strong>: 14 Sep 2024
                  </li>
                </ul>
              </div>

              <div className="portfolio-info">
              <div className="onam-events">
                <p>🌟 <strong>Melbourne Malayalee Association Presents: Onappulari 2024</strong> 🌟</p>
                <p>Join us for an unforgettable day of celebration as the Melbourne Malayalee Association hosts Onappulari 2024!</p>
                <p>🎉 <strong>Event Highlights:</strong></p>
                <p>👩&zwj;⚕️ <strong>(10:00 AM) Welcome and Registration:</strong> Kick off the day with a warm welcome and registration. Guests are invited to arrive, check-in, and settle in for an exciting day ahead.</p>
                <p>🎶 <strong>(10:30 AM) Traditional Onasadhya:</strong> The traditional floral arrangement, Pookkalam, will be created, followed by a grand Onasadhya feast. Experience the rich cultural heritage and flavors of Kerala with this elaborate meal..</p>
                <p>🎭 <strong>(12:30 PM) Cultural Programs:</strong> Enjoy a vibrant showcase of cultural performances, featuring traditional music, dance, and other art forms that celebrate our diverse heritage..</p>
                <p>🍽️ <strong>(02:30 PM) Public meetingwith Guests:</strong> Join us for a public meeting featuring distinguished guests. Important announcements and discussions will be held during this session.</p>
                <p>🎶 <strong>(03:00 PM) Lakshmi Jayan Musical:</strong> Renowned artist Lakshmi Jayan will take the stage, delivering a mesmerizing musical performance that promises to captivate the audience.</p>
                <p>🎶 <strong>(04:30 PM) Snacks and Tea, Cultural Programs continues:</strong> Take a break with some light snacks and refreshing tea, available at the counter. Relax and recharge for the remaining events.</p>
                <p>🎭 <strong>(05:00 PM) Lakshmi Jayan Musical and Cultural Programs continues:</strong> The musical performance by Lakshmi Jayan will continue alongside the ongoing cultural programmes, providing a seamless blend of music and traditional arts.</p>
                <p>🎭 <strong>(06:30 PM) Tug of War:</strong> Get ready for some fun and excitement with a traditional tug of war. Participants and spectators alike will enjoy this lively competition.</p>
                <p>🍽️ <strong>(07:15 PM) Vote of Thanks:</strong> Conclude the day with a formal vote of thanks, expressing gratitude to all who participated and contributed to the success of the event.</p>
                <p>Don't miss this opportunity to come together as a community, celebrate the spirit of malayali, enjoy a cultural extravaganza, and create lasting memories. Book your tickets now and join us for an unforgettable day of joy and entertainment!</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </>
  );
};

export default Onam;
