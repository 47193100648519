import React, { useState } from 'react';

const About = () => {
    return (
        <>
        <section id="about" className="about section-bg">
            <div className="container">
              <div className="row">
                <div
                  className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative"
                  data-aos="fade-right"
                ></div>

                <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                  <h4 data-aos="fade-up">About us</h4>
                  <h3 data-aos="fade-up">
                    About the Melbourne Malayalee Association (MMA)
                  </h3>
                  <p data-aos="fade-up">
                    Melbourne Malayalee Association (MMA) is a cultural
                    community formed in 2009 and transformed into an association
                    in 2017 by like-minded people representing the tradition and
                    rich heritage of God’s own country, Kerala, India. MMA is a
                    registered non-profit organisation of Keralite’s(people from
                    state of Kerala in India) residing in Whittlesea City
                    council and neighbouring Suburbs. Our aim is to preserve and
                    maintain our culture and traditions and to help ourcommunity
                    in integrating with wider Australian community, while
                    maintain our values and cultural identity. As a Registered
                    Charity, the main objective of the association is to promote
                    the benefit of the inhabitants of Whittlesea council and
                    surrounding areas without distinction of sex, sexual
                    orientation, age, disability, and race or of political,
                    religious or other opinions. By associating we can work
                    together to provide each other facilities with the interests
                    of social welfare, recreation and leisure-time activities to
                    improve our life conditions. MMA is planning to conduct
                    numerous cultural programmes, Arts and sports activities
                    throughout the year for all age groups. Association provides
                    various opportunities and encourages its members to
                    participate and take leadership in all activities to enhance
                    their skills and talents. MMA provides a forum for the
                    Malayalee’s residing in and around Whittlesea to meet and
                    expand their friendship and exchange their views and
                    artistic talents and cultural heritage.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </>
    );
}

export default About;