import React from "react";
import { Route, Routes } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Home/Home";


class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
        </Routes>
      </div>
    );
  }
}

export default App;
