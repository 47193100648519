import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const SwiperModule = () => {
    const [slides, setSlides] = React.useState(0);
    const setSlidesPerview = () => {
        setSlides(
            window.innerWidth <= 550
                ? 1
                : window.innerWidth <= 720
                    ? 2
                    : window.innerWidth > 720
                        ? 3
                        : 0
        );
    };
    React.useEffect(() => {
        //Initially set the amount of slides on page load
        setSlidesPerview();
        // Add the event listner on component mount
        window.addEventListener("resize", setSlidesPerview);

        // Remove the listner when component unmounts
        return () => {
            window.removeEventListener("resize", setSlidesPerview);
        };
    }, []);
    return (

        <Swiper
            slidesPerView={slides}
            spaceBetween={30}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
        >
            <SwiperSlide><img src="assets/img/clients/event_apka1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/gold_aussie1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/gold_ogr1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/gold_taxperts1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/silver_bk1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/silver_costco1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/silver_dental1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/silver_grace1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/silver_ideal1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/silver_indiagate1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/silver_sehion1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/silver_yeste1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/silver_bom1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
            <SwiperSlide><img src="assets/img/clients/event_apka1.jpg" className="img-fluid" alt=""></img></SwiperSlide>
        </Swiper>
    );
}

export default SwiperModule;