import React, { useState } from "react";

import Header from "../../Common/Header";
import Topbar from "../../Common/Topbar.js";
import Background from "./Background";
import Clients from "./Clients";
import Contact from "./Contact";
import Event from "./Event";
import Onam from "./Onam";
import About from "./About";
import Footer from "../../Common/Footer";
import Welcome from "./Welcome";

const Home = () => {
  return (
    <>
      <>
        <Topbar />
        <Header />
      </>

      <Background />
      <main id="main">
        <Welcome />
        <Onam />
        
        <About />
        <Contact />
      </main>

      <Footer />
    </>
  );
};

export default Home;
