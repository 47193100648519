import React, { useState } from "react";

const Header = () => {
  const [navbarMobile, setNavbarMobile] = useState(false);
  return (
    <>
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <a href="index.html">
              {/* <img src="assets/img/mma_logo.png" alt="" class="img-fluid" /> */}
              <img
                src="assets/img/mma_title3.png"
                alt="Melbourne Malayalee Association"
                className="img-fluid"
              />
            </a>
            {/* <h1><a href="index.html">Melbourne Malayalee Association(MMA)</a></h1> */}
          </div>

          <nav
            id="navbar"
            className={navbarMobile ? "navbar navbar-mobile" : "navbar"}
          >
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#services">
                  Events
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="#portfolio">
                  Gallery
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#team">
                  Officials
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#pricing">
                  Contact Us
                </a>
              </li>
            </ul>
            <i
              className={
                navbarMobile
                  ? "bi bi-x mobile-nav-toggle"
                  : "bi bi-list mobile-nav-toggle"
              }
              onClick={() => setNavbarMobile(!navbarMobile)}
            ></i>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
