import React, { useState } from "react";

const Welcome = () => {
  return (
    <>
      <section id="why-us" className="why-us">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5" data-aos="fade-up">
              <div className="content">
                <h3>Welcome to MMA</h3>
                <h4>(f.k.a Whittlsea Malayalee Association)</h4>
                <p>
                  Melbourne Malayalee Association (MMA) is a cultural community
                  formed in 2009 and transformed into an association in 2017 by
                  like-minded people representing the tradition and rich
                  heritage of God’s own country, Kerala.
                </p>
                <div className="text-center">
                  <a href="#" className="more-btn">
                    Learn More <i className="bx bx-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 d-flex">
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">
                  <div
                    className="col-xl-4 d-flex align-items-stretch"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-receipt"></i>
                      <h4>Our Vision</h4>
                      <p>
                        MMA Vision is to have a strong community to preserve our
                        cultural heritage and community values
                      </p>
                      <div className="text-center">
                        <a href="#" className="more-btn">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 d-flex align-items-stretch"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-cube-alt"></i>
                      <h4>Our Mission</h4>
                      <p>
                        Our Mission is to maintain a very close and strong
                        community feeling among the members
                      </p>
                      <div className="text-center">
                        <a href="#" className="more-btn">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-4 d-flex align-items-stretch"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-images"></i>
                      <h4>Our Objectives</h4>
                      <p>
                        To take an active role in furthering the interests of
                        our Community/other Communities.
                      </p>
                      <div className="text-center">
                        <a href="#" className="more-btn">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
