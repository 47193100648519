import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

const SwiperEventModule = () => {
  return (
    <Swiper
      slidesPerView={1}
      autoplay={{
        delay: 3000 * 2,
        disableOnInteraction: true,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <img
          src="assets/img/mma-onam-2024.jpg"
          className="img-fluid"
          alt=""
        ></img>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="assets/img/onam-2024-1.jpg"
          className="img-fluid"
          alt=""
        ></img>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="assets/img/onam-2024-2.jpg"
          className="img-fluid"
          alt=""
        ></img>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="assets/img/onam-2024-3.jpg"
          className="img-fluid"
          alt=""
        ></img>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="assets/img/onam-2024-4.jpg"
          className="img-fluid"
          alt=""
        ></img>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="assets/img/onam-2024-5.jpg"
          className="img-fluid"
          alt=""
        ></img>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="assets/img/onam-2024-6.jpg"
          className="img-fluid"
          alt=""
        ></img>
      </SwiperSlide>
      {/* <SwiperSlide >
        <div
          className="fb-video"
          data-href="https://www.facebook.com/MelbourneMalayalee/videos/1379034460158019"
          data-width="500"
          data-show-text="false"
          style={{ maxWidth: '96%' }}
        >
          <blockquote
            cite="https://www.facebook.com/MelbourneMalayalee/videos/1379034460158019/"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/MelbourneMalayalee/videos/1379034460158019/"></a>
            <p></p>Posted by{" "}
            <a href="https://www.facebook.com/MelbourneMalayalee">
              Melbourne Malayalee Association,Melbourne
            </a>{" "}
            on Sunday, May 12, 2024
          </blockquote>
        </div>
      </SwiperSlide> */}
    </Swiper>
  );
};

export default SwiperEventModule;
