import React, { useState } from 'react';

const Background = () => {
    return (
        <>
        <section
          id="hero"
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="container" data-aos="fade-in">
            <h1>Melbourne Malayalee Association (MMA)</h1>
            <h2>Celebrate Malayalee life in Melbourne</h2>
            <div className="d-flex align-items-center">
              <i className="bx bxs-right-arrow-alt get-started-icon"></i>
              <a href="https://www.ticketebo.com.au/melbourne-malayalee-association/mma-onappulari-2024" className="btn-get-started">
                Book Now
              </a>
              {/* <a
                href="https://www.melbournemalayaleeassociation.com.au"
                className="btn-get-started"
              >
                Join Us
              </a> */}
            </div>
          </div>
        </section>
        </>
    );
}

export default Background;